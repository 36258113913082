"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var queryString_1 = require("../helpers/queryString");
var FormModel = /** @class */ (function () {
    function FormModel($http, APPURL) {
        this.URLS = {
            FETCH: APPURL + "/api/v1/marketing/forms",
            UPDATE: APPURL + "/api/v1/marketing/forms",
            CREATE: APPURL + "/api/v1/marketing/forms",
        };
        this.$http = $http;
        this.data;
        this.extract = this.extract.bind(this);
        this.cacheResults = this.cacheResults.bind(this);
    }
    FormModel.prototype.extract = function (result) {
        return result.data;
    };
    FormModel.prototype.cacheResults = function (result) {
        this.data = this.extract(result);
        return this.data;
    };
    FormModel.prototype.getForm = function (id) {
        return this.$http.get(this.URLS.FETCH + "/" + id).then(this.cacheResults);
    };
    FormModel.prototype.getForms = function (data) {
        if (data === void 0) { data = null; }
        if (data !== null) {
            return this.$http
                .get(this.URLS.FETCH + "?" + (0, queryString_1.toQueryString)(data))
                .then(this.cacheResults);
        }
        return this.$http.get(this.URLS.FETCH).then(this.cacheResults);
    };
    FormModel.prototype.createForm = function (data) {
        return this.$http.post(this.URLS.FETCH, data).then(this.cacheResults);
    };
    FormModel.prototype.updateFormTemplate = function (template_id, data) {
        return this.$http
            .put(this.URLS.FETCH + "/" + template_id, data)
            .then(this.cacheResults);
    };
    FormModel.prototype.getQuestions = function (form_id, data) {
        if (data === void 0) { data = null; }
        if (data !== null) {
            return this.$http
                .get(this.URLS.FETCH + "/" + form_id + "/questions?" + (0, queryString_1.toQueryString)(data))
                .then(this.cacheResults);
        }
        return this.$http
            .get(this.URLS.FETCH + "/" + form_id + "/questions")
            .then(this.cacheResults);
    };
    FormModel.prototype.destroy = function (id) {
        return this.$http
            .delete(this.URLS.FETCH + "/" + id)
            .then(this.cacheResults);
    };
    return FormModel;
}());
FormModel.$inject = ["$http", "APPURL"];
exports.default = FormModel;
