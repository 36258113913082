"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var AdminStorefrontSettingsController = /** @class */ (function () {
    function AdminStorefrontSettingsController(ENV, $state, PoliciesModel, FlashService, CustomerSourceModel) {
        var _this = this;
        this.ENV = ENV;
        this.busy = true;
        this.loading = true;
        this.policies = {
            'storefront': {
                policymeta: {
                    id: 0,
                }
            }
        };
        this.PoliciesModel = PoliciesModel;
        this.FlashService = FlashService;
        this.handleTermsOfServiceChange = this.handleTermsOfServiceChange.bind(this);
        this.CustomerSourceModel = CustomerSourceModel;
        this.marketingOption = "";
        this.customerSources = [];
        this.addCustomerSource = this.addCustomerSource.bind(this);
        this.removeCustomerSource = this.removeCustomerSource.bind(this);
        CustomerSourceModel.getCustomerSources().then(function (response) {
            _this.customerSources = response.customer_sources;
        });
        PoliciesModel.getByID('storefront').then(function (response) {
            _this.policies = {
                storefront: {
                    policymeta: {
                        id: 0
                    }
                }
            };
            var policyMeta = {};
            response.policy.policymeta.forEach(function (meta) {
                policyMeta[meta.meta_key] = meta.meta_value;
            });
            /* @ts-ignore */
            _this.policies.storefront.policymeta = policyMeta;
            _this.policies.storefront.policymeta.id = response.policy.id;
            _this.busy = false;
            _this.loading = false;
        });
    }
    AdminStorefrontSettingsController.prototype.addCustomerSource = function () {
        var _this = this;
        this.CustomerSourceModel.createCustomerSource({ name: this.marketingOption }).then(function (response) {
            _this.FlashService.setMessage({
                'type': 'success',
                'message': response.message
            });
            _this.customerSources = _this.customerSources.concat([response.customer_source]);
            _this.marketingOption = '';
        }).catch(function (Error) {
        });
    };
    AdminStorefrontSettingsController.prototype.removeCustomerSource = function (id, index) {
        var _this = this;
        if (id) {
            this.CustomerSourceModel.removeCustomerSource({ id: id }).then(function (response) {
                _this.FlashService.setMessage({
                    'type': 'success',
                    'message': response.message
                });
                _this.customerSources.splice(index, 1);
            }).catch(function (Error) {
            });
        }
        else {
            this.customerSources.splice(index, 1);
        }
    };
    AdminStorefrontSettingsController.prototype.handleTermsOfServiceChange = function (data) {
        this.policies.storefront.policymeta.terms_of_service = data.html;
    };
    AdminStorefrontSettingsController.prototype.processPolicy = function () {
        var _this = this;
        this.busy = true;
        this.PoliciesModel.update(this.policies.storefront.policymeta).then(function (response) {
            _this.FlashService.setMessage({ 'type': 'success', 'message': response.message });
            _this.busy = false;
        }).catch(function (Error) {
            _this.busy = false;
        });
    };
    return AdminStorefrontSettingsController;
}());
AdminStorefrontSettingsController.$inject = ['ENV', '$state', 'PoliciesModel', 'FlashService', 'CustomerSourceModel'];
exports.default = AdminStorefrontSettingsController;
