import moment from "moment";
import values from "lodash/values";

function AdminEventsEditController(
    $scope,
    $state,
    $stateParams,
    UserService,
    UsersModel,
    EventsModel,
    FlashService,
    ClassroomsModel,
    VehiclesModel,
    event,
    campuses
) {
    let vm = this;

    vm.event = event;
    vm.vehicles = [];
    vm.instructors = [];
    vm.locations = campuses;
    vm.classrooms = [];

    vm.delete = deleteEvent;
    vm.submit = submit;
    vm.clearField = clearField;
    vm.clearForm = clearForm;
    vm.updateForm = updateForm;
    vm.getVehicles = getVehicles;
    vm.getClassrooms = getClassrooms;
    vm.updateDate = updateDate;
    vm.updateTime = updateTime;

    init();

    function init() {
        UsersModel.getInstructors({ all: true, status: "Active" }).then(
            (response) => {
                vm.instructors = [{'id' : 0, 'fullname' : 'None'}].concat(response.instructors);
                vm.instructors.forEach((instructor) => {
                    if (instructor.id === event.instructor.id) {
                        vm.event.instructor = instructor;
                    }
                });
            }
        );

        vm.user = UserService.getCurrentUser();

        // set initial location object for ng-options
        vm.locations.forEach((location) => {
            if (location.id === event.location.id) {
                vm.event.location = location;
            }
        });

        $scope.$watchGroup(
            [
                "vm.event.date",
                "vm.event.time",
                "vm.event.duration.hours",
                "vm.event.duration.minutes",
            ],
            function (newValues, oldValues) {
                if (
                    vm.event.date &&
                    vm.event.time &&
                    vm.event.duration &&
                    vm.event.location &&
                    newValues != oldValues
                ) {
                    clearForm();
                    updateForm();
                }
            }
        );
    }

    function getClassrooms(name, all) {
        let params = {
            location: vm.event.location,
        };

        if (false === all) {
            params.date = {
                date: moment(vm.event.date).format("YYYY-MM-DD"),
                time: moment(vm.event.time).format("HH:mm:ss"),
                duration:
                    parseInt(vm.event.duration.hours) * 60 +
                    parseInt(vm.event.duration.minutes),
            };
        }

        if (name) {
            params.name = name;
        }

        params.event = vm.event;

        params.exclude = {
            events: [vm.event],
        };

        ClassroomsModel.search(params).then(function (response) {
            vm.classrooms = values(response.classrooms);
        });
    }

    function getVehicles(name, all) {
        let params = {
            location: vm.event.location,
            status: "available",
        };

        if (name) {
            params.name = name;
        }

        if (false === all) {
            params.date = {
                date: moment(vm.event.date).format("YYYY-MM-DD"),
                time: moment(vm.event.time).format("HH:mm:ss"),
                duration:
                    parseInt(vm.event.duration.hours) * 60 +
                    parseInt(vm.event.duration.minutes),
            };
        }

        params.event = vm.event;

        params.exclude = {
            events: [vm.event],
        };

        VehiclesModel.searchVehicles(params).then(function (response) {
            vm.vehicles = response.vehicles;
        });
    }

    function updateForm() {
        vm.getClassrooms(null, false);
        vm.getVehicles(null, false);
    }

    function clearForm() {
        vm.event.classroom = null;
        vm.event.vehicle = null;
    }

    function clearField(field) {
        vm.event[field] = null;
    }

    function deleteEvent() {
        EventsModel.delete(vm.event).then(function (response) {
            FlashService.setMessage({
                type: "success",
                message: response.message,
            });
            $state.go("drivecoach.admin.calendar", {}, { reload: true });
        });
    }

    function submit(isValid) {
        vm.submitted = true;

        if (isValid) {
            const payload = Object.assign({}, vm.event);
            payload.formattedDate = moment(vm.event.date).format("MM/DD/YYYY");
            payload.formattedTime = moment(vm.event.time).format("HH:mm:ss");

            if (payload.duration.hours === "0") {
                payload.duration.hours = 0;
            } else {
                payload.duration.hours = parseInt(payload.duration.hours);
            }

            if (payload.duration.minutes === "00") {
                payload.duration.minutes = 0;
            } else {
                payload.duration.minutes = parseInt(payload.duration.minutes);
            }

            if (payload.instructor.id === 0) {
                delete payload.instructor;
            }

            EventsModel.update(payload)
                .then((response) => {
                    FlashService.setMessage({
                        type: "success",
                        message: response.message,
                    });

                    vm.submitted = false;
                    $state.go("drivecoach.admin.calendar", {}, { reload: true });
                })
                .catch((Error) => {
                    vm.submitted = false;
                });
        } else {
            FlashService.setMessage({
                type: "error",
                message: "Please fill in the form.",
            });
        }
    }

    function updateDate(newDate) {
        vm.event.date = newDate.selected[0];
    }

    function updateTime(newTime) {
        vm.event.time = newTime.selected[0];
    }
}

AdminEventsEditController.$inject = [
    "$scope",
    "$state",
    "$stateParams",
    "UserService",
    "UsersModel",
    "EventsModel",
    "FlashService",
    "ClassroomsModel",
    "VehiclesModel",
    "event",
    "campuses",
];
export default AdminEventsEditController;
