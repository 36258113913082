function ExceptionHandler($log, ENV, UserService) {
  return function(exception, cause) {
    $log.error(exception);
    if (ENV === "production" || ENV === "development" || ENV === "staging" || ENV === "dev") {

    }
  };
}

ExceptionHandler.$inject = ['$log', 'ENV', 'UserService'];
export default ExceptionHandler;
