<template>
  <dcLoading v-if="loading"></dcLoading>

  <div class="row" v-else-if="!loading">
    <div class="col-sm-9">
      <form name="forms" role="form" novalidate>
        <div class="form-group row">
          <div class="col-sm-6 col-md-8" :class="{'form-group--error': $v.course.name.$error }">
            <label for="course-name">Course Name</label>
            <input type="text" v-model="course.name" @input="validateInput('name')" class="form-control" id="course-name" placeholder="Enter Course Name, e.g. Drivers Ed July 2020">
          </div>
          <div class="col-sm-6 col-md-4" :class="{'form-group--error': $v.course.type.$error }">
            <label for="course-type">Course Type</label>
            <select v-if="options.courseTypes.length" v-model="course.type" class="form-control" id="course-type">
              <option v-for="(type) in options.courseTypes" :value="type.id" :key="type.id">
                {{ type.name }}
              </option>
            </select>
            <p v-else>Please create a course type <a @click="handleLinkClick('drivecoach.admin.settings.policies.courses')">in your settings</a> before you create a course</p>
          </div>
        </div>
        
        <div class="form-group row">
          <div class="col-sm-4 col-md-3" :class="{'form-group--error': $v.course.number_of_seats.$error }">
            <label for="course-number-of-seats">Number of Students</label>
            <input type="number" min="0" v-model="course.number_of_seats" @input="validateInput('number_of_seats')" class="form-control" id="course-number-of-seats" placeholder="10">
          </div>
          <div class="col-sm-4" :class="{'form-group--error': $v.course.location.$error }">
            <label for="course-location">Location</label>
            <select v-model="course.location" class="form-control" id="course-location" @change="handleLocationChange($event)">
              <option v-for="(location) in options.locations" :value="location.id" :key="location.id">
                {{ location.name }}
              </option>
            </select>
          </div>
          <div class="col-sm-4" :class="{'form-group--error': $v.course.classroom.$error }">
            <label for="course-classroom">Classroom</label>
            <select v-if="options.classrooms.length" v-model="course.classroom" class="form-control" id="course-classroom">
              <option v-for="(classroom, index) in options.classrooms" :value="classroom.id" :key="classroom.id">
                {{ classroom.name }}
              </option>
            </select>
            <p v-else>Please add a classroom for this location before you create a course</p>
          </div>
        </div>
        
        <div class="form-group row">
          <div class="col-sm-12">
            <label for="course-description">Course Description</label>
            <dcTextEditor :content="course.description"   
                          @editor-content-change="handleEditorContentChange($event, 'description')"  
                          :placeholder="'Driving School - Alpharetta'"></dcTextEditor>
          </div>
        </div>
        
        <hr>
        <div class="form-group row flex align-items-end" v-for="(classDate, index) in course.classes" :key="classDate.id">
          <div class="col-sm-3">
            <label for="">Class Date</label>
            <dcDatepicker
              :picker-datetime="classDate.date"
              :picker-min-date="today"
              v-model="classDate.date"></dcDatepicker>
          </div>
          <div class="col-sm-3">
            <label for="">Start time</label>
            <dcDatepicker 
              picker-type="time"
              :picker-datetime="classDate.startTime"
              :picker-max-time="classDate.endTime"
              v-model="classDate.startTime"></dcDatepicker>
          </div>
          <div class="col-sm-3">
            <label for="">End time</label>
            <dcDatepicker 
              picker-type="time" 
              :picker-datetime="classDate.endTime"
              :picker-min-time="classDate.startTime"
              v-model="classDate.endTime"></dcDatepicker>
          </div>
          <div class="col-sm-3">
            <label for="">Instructor</label>
            <select v-model="classDate.instructor" class="form-control" id="course-instructor">
              <option v-for="(instructor) in options.instructors" :key="instructor.id" :value="instructor.id">
                {{ instructor.fullname }}
              </option>
            </select>
          </div>
          <div class="col-sm-3">
            <button class="btn btn-red btn-block" :disabled="course.classes.length <=1 && index === 0" type="button" @click="removeClassDate(index)" :title="getTitle(index)">Remove</button>
          </div>
        </div>
        
        <div class="form-group row">
         <div class="col-sm-12">
          <button class="btn btn-blue btn-block" type="button" @click="addClassDate">Add Additional Class Date</button>
         </div>
        </div>
     
      </form>
      
    </div>
    
    <div class="col-sm-3">
      <dcCoursePreview :course="coursePreview"   
        :type="'create-course'"
        :formInvalid="$v.$invalid"
        :errors="$v.course"
        @emit-event="emitEvent($event.eventName, $event.dataObject)"
        @submit="submit"  
        ></dcCoursePreview>
      
    </div>
  </div>
</template>

<script>
  import moment from 'moment'
  import cloneDeep from 'lodash/cloneDeep'
  import dcDatepicker from '../../../common/directives/vue-directives/datepicker/Datepicker.vue'
  import dcTextEditor from '../../../common/directives/vue-directives/text-editor/TextEditor.vue'
  import dcCoursePreview from '../components/CoursePreview.vue'
  import dcLoading from '../../../common/directives/vue-directives/loading/Loading.vue'
  import dcModal from '../../../common/directives/vue-directives/modal/Modal.vue'
  
  //form validation
  import { validationMixin } from 'vuelidate'
  import { required, minLength, minValue } from 'vuelidate/lib/validators'
  
  //validation helpers
  const isDate = (value) => value instanceof Date;

  export default {
    name: 'dcCreateCourse',
    components: {
      dcDatepicker, dcCoursePreview, dcTextEditor, dcLoading, dcModal
    },
    mixins: [validationMixin],
    props: {
      'showElement': {
        type: Boolean,
        default: true
      },
      'courseTypes': { type: Array, default: () => [] },
      'locations':   { type: Array, default: () => [] },
      'classrooms':  { type: Array, default: () => [] },
      'instructors':  { type: Array, default: () => [] },
      'schoolId': { type: Number, required },
      'loading': { type: Boolean, default: false },
      'submitSuccess': { type: Boolean, default: false },
      'courseId': { type: Number },
    },
    data() {
      return {
        today: new Date(),
        course: {
          name: '',
          type: null,
          number_of_seats: 1,
          location: null, 
          classroom: null,
          description: '',
          classes: [
            {
              date: new Date(),
              startTime: moment().set({hour:9,minute:0, second:0 }).toDate(),
              endTime: moment().set({hour:16,minute:0, second:0}).toDate(),
              instructor: null,
              id: 0
            }
          ]
        },
        classId: 0,
        modalShown: false
      }
    },
    validations: {
      course: {
        name: { required, minLength: minLength(3) },
        type: { required },
        number_of_seats: { required },
        location: { required },
        classroom: { required },
        description: { minLength: minLength(10) },
        classes: {
          required,
          minLength: minLength(1),
          $each: {
            date: { required, isDate },
            startTime: { required, isDate },
            endTime: { required, isDate },
            instructor: { required },
            id: { required }
          }
        }
      }
    },
    methods: {
      addClassDate: function() {
        this.classId++;
        let classesLength = this.course.classes.length;
        let classes = this.course.classes;
        const instructor = this.options.instructors[0].id
        
        if(classesLength > 0) {
          //set initial info to have value of last added class period
          let date = cloneDeep(classes[classesLength-1].date);
          //add 1 day 
          date.setDate(date.getDate() + 1)
          classes.push({
            date: date,
            startTime: classes[classesLength-1].startTime,
            endTime: classes[classesLength-1].endTime,
            instructor: classes[classesLength-1].instructor,
            id: this.classId
          });
        } else {
          classes.push({
            date: new Date(),
            startTime: moment().set({hour:9,minute:0, second:0 }).toDate(),
            endTime: moment().set({hour:16,minute:0, second:0}).toDate(),
            instructor: instructor,
            id: 0
          });
        }
      },
      emitEvent: function(eventName, dataObject) {
        this.$emit(eventName, dataObject)
      },
      getTitle: function(index) {
        if(index === 0 && this.course.classes.length <= 1) {
          return 'You must have at least one Class Date in your Course.'
        } else return false;
      },
      handleEditorContentChange: function(value, key) {
        this.course[key] = value.html;
        this.validateInput(key);
      },
      handleLinkClick: function(destination, params) {
        this.$emit('link-click', {
          destination: destination,
          params: params
        })
      },
      handleLocationChange: function(event) {
        let location_id = event.target.value;
        this.$emit('location-change', location_id);
      },
      moment: function (date, format) {
        return moment(date).format(format);
      },
      removeClassDate: function(index) {
        this.course.classes.splice(index, 1);
      },
      validateInput(key) {
        this.$v.course[key].$touch();
      },
      submit: function() {
        //course form submit here
        if (this.$v.$invalid) {
          console.log('error')
        } else {
          let course = cloneDeep(this.course);
          course.location = { id: this.course.location }
          course.classroom = { id: this.course.classroom }
          course.number_of_seats = parseInt(course.number_of_seats);

          if (!course.description || course.description === "") {
            delete course.description;
          }

          //send full object for course type (required)
          course.type = this.selectedCourseType;

          course.formattedDate = moment(course.classes[0].date).format('YYYY-MM-DD');

          course.formattedTime = moment(course.classes[0].startTime).format('HH:mm:ss');

          let date = moment(course.classes[0].date).format('YYYY-MM-DD');
          let startTime = moment(course.classes[0].startTime).format('HH:mm:ss');
          let endTime = moment(course.classes[0].endTime).format('HH:mm:ss');

          let start = moment(date + " " + startTime);
          let end = moment(date + " " + endTime);

          let duration = moment.duration(end.diff(start));
          course.duration = { 
            hours: duration.hours(),
            minutes: duration.minutes()
          }

          course.school_id = this.schoolId;

          let formattedClasses = course.classes.map((el) => {
            let classDate = {};
            let date = moment(el.date).format('YYYY-MM-DD');
            let startTime = moment(el.startTime).format('HH:mm:ss');
            let endTime = moment(el.endTime).format('HH:mm:ss');

            classDate.start = date + " " + startTime;
            classDate.end = date + " " + endTime;

            classDate.instructor_id = el.instructor;
            return classDate;
          });

          course.classes = formattedClasses;

          this.$emit('submit', course);
        }
      }
    },
    mounted() {
      let course = this.course;
      course.location = this.options.locations[0].id;
      course.type = this.options.courseTypes[0].id;
      course.classes[0].instructor = this.options.instructors[0].id;
      if (this.options.classrooms.length > 0) {
        course.classroom = this.options.classrooms[0].id;
      } else {
        course.classroom = 0;
      }
    },
    destroyed() {
    },
    created() {
    },
    computed: {
      //making these computed properties instead of part of the regular data object makes them reactive to changes in the angular state
      options: function() {
        return {
          courseTypes: this.courseTypes,
          locations: this.locations,
          classrooms: this.classrooms,
          instructors: this.instructors
        }
      },
      coursePreview: function() {
        let course = this.course
        return {
          name: course.name,
          type: this.selectedCourseType ? this.selectedCourseType.name : '',
          number_of_seats: course.number_of_seats,
          location: this.selectedLocation ? this.selectedLocation.name : '', 
          classroom: this.selectedClassroom ? this.selectedClassroom.name : '',
          description: course.description,
          classes: this.previewClasses
        }
      },
      formattedClasses: function() {
        if (this.course.classes.length) {
          return this.course.classes.map((classTime) => {
            return {
              date: moment(classTime.date).format('M / D / YYYY'),
              startTime: moment(classTime.startTime).format('hh:mm A'),
              endTime: moment(classTime.endTime).format('hh:mm A'),
              instructor: classTime.instructor
            }
          })
        }        

        return [];
      },
      previewClasses: function() {
        if (this.course.classes && this.course.classes.length) {
          const classes = this.course.classes.map(classTime => {
            return {
              date: moment(classTime.date).format('M / D / YYYY'),
              startTime: moment(classTime.startTime).format('hh:mm A'),
              endTime: moment(classTime.endTime).format('hh:mm A'),
              instructor: this.instructors.find(instructor => instructor.id === classTime.instructor)
            }
          });
          return classes;
        }

        return [];
      },
      selectedLocation: function() { return this.locations ? this.locations.find(location => location.id === this.course.location) : null },
      selectedCourseType: function() { return this.courseTypes ? this.courseTypes.find(type => type.id === this.course.type) : null },
      selectedClassroom: function() { return this.classrooms ? this.classrooms.find(classroom => classroom.id === this.course.classroom) : null },
    },
    watch: {
      submitSuccess() {
        this.modalShown = this.submitSuccess;
      }
    }
  }

</script>

<style lang="scss" scoped>
  @import '../../../../assets/css/partials/admin-courses.scss'
    
</style>
