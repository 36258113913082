import angular from 'angular'
import moment from 'moment'
import StudentDrivesClaimController from './book-drive.controller'
import template from './book-drive.html'

function config( $stateProvider, $urlRouterProvider, USER_ROLES ) {
    $stateProvider
        .state( 'drivecoach.student.drives.create', {
            url : '/create/:id',
            template : template,
            controller : StudentDrivesClaimController,
            controllerAs : 'vm',
            resolve : {
                drive : getDrive,
                locations : getLocations
            },
            data : {
                authorizedRoles : [
                    USER_ROLES.admin,
                    USER_ROLES.owner,
                    USER_ROLES.student
                ]
            }
        } )
    ;
}
config.$inject = ['$stateProvider', '$urlRouterProvider', 'USER_ROLES'];

function getLocations($stateParams, UsersModel){
    return UsersModel.getStudentPickupLocationsByDriveSession($stateParams.id).then(response => {
        response.pickuplocations.map(location => {
            location.name = location.name + ' ' + location.address.street;
            return location;
        });
        response.dropofflocations.map(location =>{
            location.name = location.name + ' ' + location.address.street;
            return location;
        });
        return response;
    });
}
getLocations.$inject = ['$stateParams', 'UsersModel'];

function getDrive( $stateParams, DrivesModel ) {
    return DrivesModel.getDriveByID( $stateParams.id ).then( response => {
        response.drive.start_time = moment(response.drive.start_time, 'HH:mm:s').format('h:mm a');
        const minutes = moment.duration(response.drive.session_length, 'minutes');
        response.drive.end_time = moment(response.drive.start_time, 'h:mm a').add( minutes, 'minutes').format('h:mm a');

        // @todo this is a hack for a bug fix
        if (!response.drive.type.allow_custom_dropoff_locations === false && !response.drive.dropofflocation) {
            response.drive.dropofflocation = response.drive.location;
        }
        if (!response.drive.type.allow_custom_pickup_locations === false && !response.drive.pickuplocation) {
            response.drive.dropofflocation = response.drive.location;
        }

        return response.drive;
    });
}
getDrive.$inject = ['$stateParams', 'DrivesModel'];

export default angular.module( 'student.drives.create', [] )
    .controller( 'StudentDrivesClaimController', StudentDrivesClaimController )
    .config( config )
;
