<template>
    <section>
        <div style="display: flex; padding-left: 2em;">
          <div><span style="font-size: 250%">{{ document.title || 'Untitled' }}</span></div> 
          <div style="display: flex; flex-direction: column; justify-content: center; margin-left: 1em;"><span class="badge">{{ document.status }}</span></div>
        </div>
        <div v-if="editor" class="ds-document-editor-nav">
            <div class="control-group">
                <div class="button-group">
                    <button @click="editor.chain().focus().toggleBold().run()" :disabled="!editor.can().chain().focus().toggleBold().run()" :class="{ 'is-active': editor.isActive('bold') }">
                      Bold
                    </button>
                    <button @click="editor.chain().focus().toggleItalic().run()" :disabled="!editor.can().chain().focus().toggleItalic().run()" :class="{ 'is-active': editor.isActive('italic') }">
                      Italic
                    </button>
                    <button @click="editor.chain().focus().toggleStrike().run()" :disabled="!editor.can().chain().focus().toggleStrike().run()" :class="{ 'is-active': editor.isActive('strike') }">
                      Strike
                    </button>
                </div>
                <div class="button-group">
                    <button @click.prevent="toggleDropdown('heading')">Heading</button>
                    <div class="dropdown" :class="{ 'dropdown-active' : activeDropdown == 'heading'}">
                      <button @click="editor.chain().focus().toggleHeading({ level: 1 }).run()" :class="{ 'is-active': editor.isActive('heading', { level: 1 }) }">
                        H1
                      </button>
                      <button @click="editor.chain().focus().toggleHeading({ level: 2 }).run()" :class="{ 'is-active': editor.isActive('heading', { level: 2 }) }">
                        H2
                      </button>
                      <button @click="editor.chain().focus().toggleHeading({ level: 3 }).run()" :class="{ 'is-active': editor.isActive('heading', { level: 3 }) }">
                        H3
                      </button>
                      <button @click="editor.chain().focus().toggleHeading({ level: 4 }).run()" :class="{ 'is-active': editor.isActive('heading', { level: 4 }) }">
                        H4
                      </button>
                      <button @click="editor.chain().focus().toggleHeading({ level: 5 }).run()" :class="{ 'is-active': editor.isActive('heading', { level: 5 }) }">
                        H5
                      </button>
                      <button @click="editor.chain().focus().toggleHeading({ level: 6 }).run()" :class="{ 'is-active': editor.isActive('heading', { level: 6 }) }">
                        H6
                      </button>
                    </div>
                </div>
                <div class="button-group">
                    <button @click.prevent="toggleDropdown('list')">List</button>
                    <div class="dropdown" :class="{ 'dropdown-active' : activeDropdown == 'list'}">
                      <button @click="editor.chain().focus().toggleBulletList().run()" :class="{ 'is-active': editor.isActive('bulletList') }">
                        Bullet list
                      </button>
                      <button @click="editor.chain().focus().toggleOrderedList().run()" :class="{ 'is-active': editor.isActive('orderedList') }">
                        Ordered list
                      </button>
                    </div>
                </div>
                <div class="button-group">
                    <button @click="editor.chain().focus().toggleBlockquote().run()" :class="{ 'is-active': editor.isActive('blockquote') }">
                      Blockquote
                    </button>
                    <button @click="editor.chain().focus().setHorizontalRule().run()">
                      Horizontal rule
                    </button>
                    <button @click="editor.chain().focus().setHardBreak().run()">
                      Hard break
                    </button>
                </div>
                <div class="button-group">
                    <button @click="editor.chain().focus().undo().run()" :disabled="!editor.can().chain().focus().undo().run()">
                      Undo
                    </button>
                    <button @click="editor.chain().focus().redo().run()" :disabled="!editor.can().chain().focus().redo().run()">
                      Redo
                    </button>
                </div>
                <div class="button-group">
                    <button @click.prevent="toggleDropdown('align')">Align</button>
                    <div class="dropdown" :class="{ 'dropdown-active' : activeDropdown == 'align'}">
                      <button @click="editor.chain().focus().setTextAlign('left').run()" :class="{ 'is-active': editor.isActive({ textAlign: 'left' }) }">
                        Left
                      </button>
                      <button @click="editor.chain().focus().setTextAlign('center').run()" :class="{ 'is-active': editor.isActive({ textAlign: 'center' }) }">
                        Center
                      </button>
                      <button @click="editor.chain().focus().setTextAlign('right').run()" :class="{ 'is-active': editor.isActive({ textAlign: 'right' }) }">
                        Right
                      </button>
                      <button @click="editor.chain().focus().setTextAlign('justify').run()" :class="{ 'is-active': editor.isActive({ textAlign: 'justify' }) }">
                        Justify
                      </button>
                      <button @click="editor.chain().focus().unsetTextAlign().run()">
                        Unset text align
                      </button>
                    </div>
                </div>
                <div class="button-group">
                    <button @click="setLink" :class="{ 'is-active': editor.isActive('link') }">
                      Set link
                    </button>
                    <button @click="editor.chain().focus().unsetLink().run()" :disabled="!editor.isActive('link')">
                      Unset link
                    </button>
                </div>
            </div>
        </div> 

        <section style="display: flex; border-top: 1px solid #ccc;">
            <div style="display: block; width: 80%;">
                <div v-if="editor">
                    <div style="display: flex; width: 100%; padding: 2em; ">
                        <div style="text-align: left; width: 50%;">Page: 1</div>
                        <div style="text-align: right; width: 50%"><button class="btn btn-dc">Add Page</button></div>
                    </div>
                </div>
                <div style="padding-top: 1em;">
                    <editor-content :editor="editor" />
                </div>
            </div>
            <div style="display: block; width: 20%; padding-top: 2em; padding-left: 2em; padding-right: 2em;" class="ds-document-editor-sidebar">
 
                <section style="margin-bottom: 2.4em">
                  <div style="margin-bottom: 1em;"><button class="btn btn-dc btn-block" @click="addImage">Add image from URL</button></div>
                  <div style="margin-bottom: 1em;">
                    <button class="btn btn-dc btn-block" @click="editor.chain().focus().setParagraph().run()">
                      Text
                    </button>
                  </div>
                </section>

                <section>

                  <div class="form-group">
                    <h3>Fillable Fields For</h3>
                    <select class="form-control">
                        <option>Driving School Staff</option>
                        <option>Student</option>
                        <option>Guardian</option>
                    </select>
                  </div>

                    <section>
                      <div style="margin-bottom: 1em;"><button class="btn btn-dc btn-block" @click="editor.chain().focus().setStudentSignature().run()">Student Signature</button></div>
                      <div style="margin-bottom: 1em;"><button class="btn btn-dc btn-block" @click="editor.chain().focus().setStudentSignature().run()">Guardian Signature</button></div>
                      <div style="margin-bottom: 1em;"><button class="btn btn-dc btn-block" @click="editor.chain().focus().setStudentSignature().run()">Driving School Staff</button></div>
                    </section>
                </section>
            </div>
        </section>

    </section>
</template>
<style>
div[data-custom] {
  border: 1px solid red;
  padding: 10px;
  background-color: rebeccapurple;
}
</style>
<script>
  import Image from '@tiptap/extension-image'
  import TextAlign from '@tiptap/extension-text-align'
  import Link from '@tiptap/extension-link'
  import { Editor, EditorContent } from '@tiptap/vue-2'
  import { Extension, Node } from '@tiptap/core';
  import StarterKit from '@tiptap/starter-kit'
  import TextInput from './TextInput.vue'

  export default {
    components: {
      EditorContent,
    },

    props: {
      value: {
        type: String,
        default: '',
      },
    },

    data() {
      return {
        activeDropdown: '',
        document: {
          title: '',
          status: 'DRAFT',
          pages: []
        },
        editor: null,
      }
    },

    methods: {
      toggleDropdown(dropdown){
        if (this.activeDropdown == dropdown) {
          this.activeDropdown = '';
        } else {
          this.activeDropdown = dropdown;
        }
      },
      setLink() {
        const previousUrl = this.editor.getAttributes('link').href
        const url = window.prompt('URL', previousUrl)

        // cancelled
        if (url === null) {
          return
        }

        // empty
        if (url === '') {
          this.editor
            .chain()
            .focus()
            .extendMarkRange('link')
            .unsetLink()
            .run()

          return
        }

        // update link
        this.editor
          .chain()
          .focus()
          .extendMarkRange('link')
          .setLink({ href: url })
          .run()
      },
      addImage() {
          const url = window.prompt('URL');

          if (url) {
            this.editor.chain().focus().setImage({ src: url }).run()
          }
      },
    },
    watch: {
      value(value) {
        // HTML
        const isSame = this.editor.getHTML() === value

        // JSON
        // const isSame = JSON.stringify(this.editor.getJSON()) === JSON.stringify(value)

        if (isSame) {
          return
        }

        this.editor.commands.setContent(value, false)
      },
    },

    mounted() {
        const CustomExtension = Node.create({
          name: 'studentSignature',
          group: 'block',
          content: 'inline*',
          // addNodeView() {
          //   return ({ editor, node, getPos, HTMLAttributes, decorations, extension }) => {
          //     const dom = document.createElement('div');
          //     dom.setAttribute('style', 'max-width: 200px;  height: 100px;');
          //     const inner = document.createElement('div');
          //     inner.setAttribute('style', 'padding: 2em; padding-left: 0px; border-bottom: 2px solid #000;');
          //     inner.innerHTML = 'Student Signature';
          //     dom.appendChild(inner);
          //     return {
          //       dom,
          //     }
          //   }
          // },
          addAttributes() {
            return {
              option_name: {
                // Set the color attribute according to the value of the `data-color` attribute
                default: '',
              },
              option_value: {
                default: ''
              },
              fillable_by: {
                default: []
              }
            }
          },
          parseHTML() {
            return [
              {
                tag: 'text-input',
              },
            ];
          },

          renderHTML({ HTMLAttributes }) {
            return ['div', { ...HTMLAttributes, 'data-custom': HTMLAttributes.dataCustom }, 0];
          },
          addCommands() {
            return {
              setStudentSignature: (attributes) => ({ commands }) => {
                return commands.insertContent({ type: this.name, attributes: attributes });
              },
            };
          },
        });
      this.editor = new Editor({
        content: this.value,
        editorProps: {
            attributes: {
                class: 'ds-document-editor'
            }
        },
        extensions: [StarterKit, Image, Link, TextAlign.configure({
          types: ['heading', 'paragraph'],
        }), CustomExtension],
        onUpdate: () => {
          // HTML
          this.$emit('input', this.editor.getHTML())

          // JSON
          // this.$emit('input', this.editor.getJSON())
        },
      })
    },

    beforeDestroy() {
      this.editor.destroy()
    },
  }
</script>

