<template>
  <div class="app-ver">
    <small>{{ number }}</small>
  </div>
</template>

<script>
//right now this works by changing the default prop to update the number. it is designed to pass in the number as a prop also if necessary in the future
export default {
  name: "dcVersionNumber",
  props: {
      number: { type: String, default: "1.19.15" },
  },
};
</script>
