"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var BillingModel = /** @class */ (function () {
    function BillingModel($http, APPURL) {
        this.$http = $http;
        this.URLS = {
            FETCH: APPURL + "/api/v1/billing",
            UPDATE: APPURL + "/api/v1/billing",
            CREATE: APPURL + "/api/v1/billing",
        };
        this.data = {};
        this.extract = this.extract.bind(this);
        this.cacheResults = this.cacheResults.bind(this);
    }
    BillingModel.prototype.extract = function (result) {
        return result.data;
    };
    BillingModel.prototype.cacheResults = function (result) {
        this.data = this.extract(result);
        return this.data;
    };
    BillingModel.prototype.getBillingInfo = function () {
        return this.$http.get(this.URLS.FETCH).then(this.cacheResults);
    };
    return BillingModel;
}());
BillingModel.$inject = ["$http", "APPURL"];
exports.default = BillingModel;
