<template>
  <section class="container-fluid">
    <div class="row">
      <div class="col-xs-12">
        <form @submit.prevent="submit()" novalidate>
          <div ref="payment"></div>
          <div class="text-center">
            <button class="btn btn-dc" :disabled="buttonDisabled" style="margin-top: 2em;">{{ buttonText }}</button>
          </div>
        </form>
      </div>
    </div>
  </section>
</template>

<script>
import { loadStripe } from "@stripe/stripe-js";
import { Config } from "../../../../DriveScout/Config.js";

let stripe;
let elements;

export default {
  props: {
    clientSecret: { type: String, default: "" },
    publishableKey: { type: String, default: "" },
    returnUrl: { type: String, default: "" },
    buttonText: { type: String, default: "Submit"},
    buttonDisabled: { type: Boolean, default: false},
    mode: { type: String, default: "payment"},
    beforeSubmit: { type: Function, async default() {
      return {}
    } },
    elementOptions: {
      type: Object,
      default: function(){
        return {
            wallets: {
                googlePay: 'never',
                applePay: 'never',
            },
        }
      }
    },
  },
  data() {
    return {
      confirmParams: {
        return_url: "", // success url
      },
    };
  },
  async mounted() {
    this.elementOptions.clientSecret = this.clientSecret;
    this.confirmParams.return_url = this.returnUrl;

    let pk = this.publishableKey;

    this.stripe = await loadStripe(pk);

    if (this.clientSecret !== ""){
      const clientSecret = this.clientSecret;
      this.elements = this.stripe.elements({ clientSecret });
      const paymentElement = this.elements.create("payment", this.elementOptions);
      paymentElement.mount(this.$refs.payment);
    } else {
      const options = {
        paymentMethodCreation: 'manual', mode: 'payment',
        ...this.elementOptions
      };
      delete options.clientSecret;
      this.elements = this.stripe.elements(options);
      const paymentElement = this.elements.create("payment");
      paymentElement.mount(this.$refs.payment);
    }
  },
  methods: {
    async submit() {

      const response = await this.beforeSubmit();

      if (this.mode === "payment"){
        
        // Trigger form validation and wallet collection
        const {error: submitError} = await this.elements.submit();
        if (submitError) {
          handleError(submitError);
          return;
        }

        // Create the ConfirmationToken using the details collected by the Payment Element
        // and additional shipping information
        const elements = this.elements;
        const {error, confirmationToken} = await this.stripe.createConfirmationToken({
          elements,
          params: {
            return_url: this.returnUrl
          }
        });

        if (error) {
          // This point is only reached if there's an immediate error when
          // creating the ConfirmationToken. Show the error to your customer (for example, payment details incomplete)
          handleError(error);
          return;
        }

        this.tokenCreated(confirmationToken.id);

      } else {
        const elements = this.elements;
        const { error } = await this.stripe.confirmSetup({
          elements,
          confirmParams: {
            return_url: this.returnUrl
          }
        });
      }

    },
    tokenCreated(token) {
      this.$emit("stripeCardTokenCreated", token);
    },
    pay() {
      this.$refs.paymentRef.submit();
    },
  },
};
</script>
