"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var queryString_1 = require("../helpers/queryString");
var LeadsModel = /** @class */ (function () {
    function LeadsModel($http, APPURL) {
        this.$http = $http;
        this.URLS = {
            FETCH: APPURL + '/api/v1/crm/leads',
            UPDATE: APPURL + '/api/v1/crm/leads',
            CREATE: APPURL + '/api/v1/crm/leads'
        };
        this.data = [];
        this.extract = this.extract.bind(this);
        this.cacheResults = this.cacheResults.bind(this);
    }
    LeadsModel.prototype.extract = function (result) {
        return result.data;
    };
    LeadsModel.prototype.cacheResults = function (result) {
        this.data = this.extract(result);
        return this.data;
    };
    LeadsModel.prototype.show = function (id) {
        return this.$http.get(this.URLS.FETCH + '/' + id).then(this.cacheResults);
    };
    ;
    LeadsModel.prototype.get = function (data) {
        if (data === void 0) { data = null; }
        if (data !== null) {
            if (data.hasOwnProperty('status') && data.status === 'all') {
                delete data.status;
            }
            return this.$http.get(this.URLS.FETCH + '?' + (0, queryString_1.toQueryString)(data)).then(this.cacheResults);
        }
        return this.$http.get(this.URLS.FETCH).then(this.cacheResults);
    };
    ;
    LeadsModel.prototype.update = function (lead) {
        return this.$http.put(this.URLS.FETCH + '/' + lead.id, lead).then(this.cacheResults);
    };
    ;
    LeadsModel.prototype.create = function (request) {
        return this.$http.post(this.URLS.FETCH, request).then(this.cacheResults);
    };
    ;
    LeadsModel.prototype.delete = function (lead) {
        return this.$http.delete(this.URLS.FETCH + '/' + lead.id).then(this.cacheResults);
    };
    ;
    return LeadsModel;
}());
LeadsModel.$inject = ['$http', 'APPURL'];
exports.default = LeadsModel;
