<template>
    <node-view-wrapper class="vue-component">
      <label>{{ node.attrs.option_name }}</label>
  
      <div class="content">
        <input :model="node.attrs.option_value" :placeholder="node.attrs.option_name">
      </div>
    </node-view-wrapper>
  </template>
  
  <script>
  import { nodeViewProps, NodeViewWrapper } from '@tiptap/vue-2'
  
  export default {
    components: {
      NodeViewWrapper,
    },
  
    props: nodeViewProps,
  
    methods: {
      increase() {
        this.updateAttributes({
          count: this.node.attrs.count + 1,
        })
      },
    },
  }
  </script>