import angular from "angular";
import controller from "./purchase-online-drivers-ed.controller";
import template from "./purchase-online-drivers-ed.html";
import "ngVue";

function getBillingInfo(BillingModel) {
    return BillingModel.getBillingInfo().then((response) => {
        return response.pk;
    });
}
getBillingInfo.$inject = ["BillingModel"];

function config($stateProvider, $urlRouterProvider, USER_ROLES) {
    $stateProvider.state("drivecoach.admin.schools.purchaseonlinede", {
        url: "/purchase-online-driverse-ed",
        controller: controller,
        controllerAs: "vm",
        template: template,
        resolve: {
            pk: getBillingInfo,
        },
        data: {
            authorizedRoles: [
                USER_ROLES.admin,
                USER_ROLES.administrator,
                USER_ROLES.owner,
            ],
        },
    });
}
config.$inject = ["$stateProvider", "$urlRouterProvider", "USER_ROLES"];

export default angular
    .module("admin.dashboard.schools.purchaseonlinede", [])
    .config(config)
    .controller("AdminSchoolPurchaseOnlineDriversEdController", controller);
