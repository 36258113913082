import flatten from 'lodash/flatten'
import filter from 'lodash/filter'
import {saveToDisk} from "../../helpers/saveToDisk";

/**
 *
 * @deprecated this is pretty much all for the old calendar, nothing here is used for vue calendar component
 */
function dcCalendarFilterController( $rootScope, UserService, DrivesFilterService, CalendarModel, DrivesModel, CalendarService, FlashService ) {

    const vm = this;
    vm.currentUser = UserService.getCurrentUser();
    vm.filterOptions = DrivesFilterService.getFilterOptions();
    vm.applyingFilter = false;
    vm.submittingDownload = false;

    vm.showDriveSessionFilters = showDriveSessionFilters;
    vm.setFilterStatus = setFilterStatus;
    vm.applyFilter = applyFilter;
    vm.submitDownload = submitDownload;

    vm.filter = DrivesFilterService.getFilterParameters();

    function showDriveSessionFilters() {
        if (vm.filter.hasOwnProperty('service') && typeof vm.filter.service.name !== 'undefined') {
            return vm.filter.service.isDriveSession;
        }
    }

    function applyFilterDataToAgenda(response){
        CalendarService.setAgendaItems(CalendarService.processAgenda(response));
        $rootScope.$broadcast('UPDATE_AGENDA');
        vm.applyingFilter = false;
    }

    function applyFilterDataToCalendar(response){
        CalendarModel.setAgenda(response);

        let formattedResponse = CalendarService.processCalendar(response);
        let entities = flatten(filter(formattedResponse, (value, key) => {
            return key !== 'flag' && key !== 'stats';
        }));
        CalendarService.setCalendarItems(entities);
        $rootScope.$broadcast('UPDATE_CALENDAR');
        vm.applyingFilter = false;
        CalendarService.enableFullCalendarControls();
    }

    function applyFilterData(response){

        if( vm.context === 'agenda' || vm.context === 'student' ){
            applyFilterDataToAgenda(response);
        }

        if(vm.context === 'calendar'){
            applyFilterDataToCalendar(response);
        }
    }

    function prepareFilterQuery() {
        const filterQuery = {
            'start_date': DrivesFilterService.getStartDate(),
            'end_date': DrivesFilterService.getEndDate(),
            'date' : DrivesFilterService.getDate(),
            'service': vm.filter.service,
            'location': vm.filter.location,
            'instructor': vm.filter.instructor,
            'vehicle': vm.filter.vehicle,
            'status': vm.filter.status,
            'statusIsnt': 'Inactive - Scheduling Conflict',
            'days' : vm.filterOptions.days, // for student calendar
            'times' : vm.filterOptions.times // for student calendar
        };
        DrivesFilterService.setFilterParameters(filterQuery);
        return filterQuery;
    }

    function getFilterMethod(){
        const filterQuery = prepareFilterQuery();
        if( vm.context === 'calendar' ){
            return CalendarModel.filterAdminCalendar(filterQuery);
        }
        if( vm.context === 'agenda' ){
            return CalendarModel.getAdminAgenda(filterQuery);
        }
        if( vm.currentUser.role.type === 'student' || vm.context === 'student' ){
            return CalendarModel.getStudentCalendar(filterQuery);
        }
    }

    /**
     * Update the calendar when the filters are changed
     */
    function applyFilter() {
        vm.applyingFilter = true;
        CalendarService.disableFullCalendarControls();
        CalendarService.clearItems();
        getFilterMethod().then(applyFilterData).catch(Error => {
            vm.applyingFilter = false;
        });;
    }

    function submitDownload() {
        vm.submittingDownload = true;
        const filterQuery = prepareFilterQuery();
        CalendarModel.downloadAdminCalendar(filterQuery).then(response => {
            FlashService.setMessage({
                'type' : 'success',
                'message' : response.message
            })
            saveToDisk(response.url, response.filename)
            vm.submittingDownload = false;
        }).catch(Error => {
            vm.submittingDownload = false;
        });
    }

    function setFilterStatus(status) {
        vm.activeTab = status;
        vm.filter.status = status;
    }

}
dcCalendarFilterController.$inject = ['$rootScope', 'UserService', 'DrivesFilterService', 'CalendarModel', 'DrivesModel', 'CalendarService', 'FlashService'];
export default dcCalendarFilterController;