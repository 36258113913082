class AdminCreateDocumentController {
  constructor(
    $state,
    $scope,
    FlashService,
    $window,
  ) {
    this.$state = $state;
    this.$scope = $scope;
    this.FlashService = FlashService;
    this.$window = $window;
    this.busy = false;
  }
}

AdminCreateDocumentController.$inject = [
  "$state",
  "$scope",
  "FlashService",
  "$window",
];

export default AdminCreateDocumentController;
