import Nav from './nav/admin-nav.module'
import FooterNav from './footernav/admin-footer-nav.module'
import ProductCategories from './product-categories/product-categories.module'
import ProductForm from './product-form/product-form.module'
import Product from './product/product.module'
import OrderForm from './order-form/order-form.module'
import ProductSessionTypes from './product-session-types/product-session-types.module'
import ProductCourseTypes from './product-course-types/product-course-types.module'
import CourseTypes from './course-types/course-types.module'
import SessionTypes from './drive-session-types/drive-session-types.module'
import BatchCreate from './batch-create-entity/batch-create-entity.module'
import DriveSessionTimeline from './drive-session-timeline/drive-session-timeline.module'
import StudentSearch from './student-search/student-search.module'
import UserSearch from './user-search/user-search.module'
import Tasks from './tasks/tasks.module'
import CreateTask from './create-task/create-task.module'
import Task from './task/task.module'
import Loading from './loading/loading.js'
import AddFee from './add-fee/add-fee.module'
import Toaster from './toaster/toaster'
import AddCustomPickupLocation from './create-adhoc-pickup-location/create-adhoc-pickup-location.module'
import DocumentEditor from './document-editor/document-editor.module'

let modules = [
    Nav.name,
    FooterNav.name,
    ProductForm.name,
    Product.name,
    OrderForm.name,
    ProductSessionTypes.name,
    ProductCourseTypes.name,
    ProductCategories.name,
    StudentSearch.name,
    UserSearch.name,
    CourseTypes.name,
    SessionTypes.name,
    BatchCreate.name,
    DriveSessionTimeline.name,
    CreateTask.name,
    Tasks.name,
    Task.name,
    AddFee.name,
    Toaster.name,
    Loading.name,
    AddCustomPickupLocation.name,
    DocumentEditor.name
];
export default angular.module( 'admin.directives', modules );
