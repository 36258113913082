"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var StudentUsermetaModel = /** @class */ (function () {
    function StudentUsermetaModel($http, APPURL) {
        this.URLS = {
            FETCH: APPURL + '/api/v1',
            UPDATE: APPURL + '/api/v1',
            CREATE: APPURL + '/api/v1'
        };
        this.$http = $http;
        this.extract = this.extract.bind(this);
        this.cacheResults = this.cacheResults.bind(this);
    }
    StudentUsermetaModel.prototype.extract = function (result) {
        return result.data;
    };
    StudentUsermetaModel.prototype.cacheResults = function (result) {
        return this.extract(result);
    };
    StudentUsermetaModel.prototype.index = function (student_id) {
        return this.$http.get(this.URLS.FETCH + '/users/students/' + student_id + '/usermeta').then(this.cacheResults.bind(this));
    };
    ;
    StudentUsermetaModel.prototype.update = function (student_id, metadata) {
        return this.$http.patch(this.URLS.FETCH + '/users/students/' + student_id + '/usermeta', metadata).then(this.cacheResults.bind(this));
    };
    ;
    return StudentUsermetaModel;
}());
StudentUsermetaModel.$inject = ['$http', 'APPURL'];
exports.default = StudentUsermetaModel;
