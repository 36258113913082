"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var AdminFormTempaltesController = /** @class */ (function () {
    function AdminFormTempaltesController($window, $rootScope, $state, FormTemplateModel, FlashService) {
        var _this = this;
        this.$window = $window;
        this.$state = $state;
        this.FormTemplateModel = FormTemplateModel;
        this.FlashService = FlashService;
        this.loading = true;
        this.formTemplates = [];
        this.page_by = 10;
        this.totalItems = 0;
        this.currentPage = 1;
        this.FormTemplateModel.getFormTemplates({
            page: this.currentPage,
            page_by: this.page_by,
        })
            .then(function (response) {
            _this.formTemplates = response.form_templates.data;
            _this.currentPage = response.form_templates.current_page;
            _this.totalItems = response.form_templates.total;
            _this.loading = false;
        })
            .catch(function (Error) {
            _this.loading = false;
        });
    }
    AdminFormTempaltesController.prototype.pageChanged = function () {
        var _this = this;
        this.loading = true;
        this.FormTemplateModel.getFormTemplates({
            page: this.currentPage,
            page_by: this.page_by,
        })
            .then(function (response) {
            _this.formTemplates = response.form_templates.data;
            _this.currentPage = response.form_templates.current_page;
            _this.totalItems = response.lesson_plans.total;
            _this.loading = false;
        })
            .catch(function (Error) {
            _this.loading = false;
        });
    };
    return AdminFormTempaltesController;
}());
AdminFormTempaltesController.$inject = [
    "$window",
    "$rootScope",
    "$state",
    "FormTemplateModel",
    "FlashService",
];
exports.default = AdminFormTempaltesController;
