"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var StudentOnlineDriversEdController = /** @class */ (function () {
    function StudentOnlineDriversEdController($state, $stateParams, UsersModel, FlashService, OnlineDriversEd, UserService) {
        var _this = this;
        this.$state = $state;
        this.$stateParams = $stateParams;
        this.UsersModel = UsersModel;
        this.FlashService = FlashService;
        this.OnlineDriversEd = OnlineDriversEd;
        this.UserService = UserService;
        this.submitted = false;
        this.pageBy = 5;
        this.currentPage = 1;
        this.loading = true;
        this.user = UserService.getCurrentUser();
        this.finals = [];
        OnlineDriversEd.viewFinals({
            student_id: this.user.id,
            page_by: this.pageBy,
        }).then(function (response) {
            _this.finals = response.online_drivers_ed_finals.data;
            _this.currentPage = response.online_drivers_ed_finals.current_page;
            _this.totalItems = response.online_drivers_ed_finals.total;
            _this.loading = false;
        });
    }
    StudentOnlineDriversEdController.prototype.formatDate = function (datestring) {
        return new Date(datestring).toLocaleDateString("en-US", {
            day: "2-digit",
            month: "2-digit",
            year: "numeric",
        });
    };
    StudentOnlineDriversEdController.prototype.pageChanged = function () {
        var _this = this;
        this.submitted = true;
        this.OnlineDriversEd.viewFinals({
            student_id: this.user.id,
            page_by: this.pageBy,
        })
            .then(function (response) {
            _this.finals = response.online_drivers_ed_finals.data;
            _this.currentPage = response.online_drivers_ed_finals.current_page;
            _this.totalItems = response.online_drivers_ed_finals.total;
        })
            .catch(function (error) {
            _this.submitted = false;
        });
    };
    return StudentOnlineDriversEdController;
}());
StudentOnlineDriversEdController.$inject = [
    "$state",
    "$stateParams",
    "UsersModel",
    "FlashService",
    "OnlineDriversEd",
    "UserService",
];
exports.default = StudentOnlineDriversEdController;
