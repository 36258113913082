"use strict";
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
var AdminEditFormTempalteController = /** @class */ (function () {
    function AdminEditFormTempalteController($window, $rootScope, $state, FormTemplateModel, FlashService, formTemplate, questions) {
        this.$window = $window;
        this.$state = $state;
        this.FormTemplateModel = FormTemplateModel;
        this.FlashService = FlashService;
        this.loading = false;
        this.submitting = false;
        this.formTemplate = formTemplate;
        this.questions = questions;
        this.viewingQuestion = true;
        this.editingQuestion = false;
        this.question = {
            name: "",
            type: "multiplechoice",
            from: 1,
            to: 10,
            options: [
                {
                    option_text: "",
                    is_correct: false,
                },
            ],
        };
        this.addOption = this.addOption.bind(this);
        this.addQuestion = this.addQuestion.bind(this);
        this.generateRange = this.generateRange.bind(this);
        this.pageChanged = this.pageChanged.bind(this);
    }
    AdminEditFormTempalteController.prototype.deleteQuestion = function (question_id) {
        var _this = this;
        var confirm = this.$window.confirm("Are you sure you want to delete this question?");
        if (confirm === false) {
            return;
        }
        this.FormTemplateModel.deleteQuestion(question_id).then(function (response) {
            _this.pageChanged();
        });
    };
    AdminEditFormTempalteController.prototype.saveQuestion = function (question) {
        var _this = this;
        this.FormTemplateModel.updateQuestion(question.id, question).then(function (response) {
            _this.FlashService.setMessage({
                type: "success",
                message: response.message,
            });
        });
    };
    AdminEditFormTempalteController.prototype.pageChanged = function () {
        var _this = this;
        this.FormTemplateModel.getQuestions(this.formTemplate.id, {
            per_page: 1,
            page: this.questions.current_page,
        }).then(function (response) {
            _this.questions = response.questions;
        });
    };
    AdminEditFormTempalteController.prototype.generateRange = function (from, to) {
        if (from === void 0) { from = 0; }
        if (to === void 0) { to = 0; }
        var r = [];
        var i = from;
        if (from >= to) {
            return r;
        }
        while (i <= to) {
            r.push(i);
            i++;
        }
        return r;
    };
    AdminEditFormTempalteController.prototype.updateFormTemplate = function () {
        var _this = this;
        this.submitting = true;
        this.FormTemplateModel.updateFormTemplate(this.formTemplate.id, this.formTemplate)
            .then(function (response) {
            _this.submitting = false;
            _this.FlashService.setMessage({
                type: "success",
                message: response.message,
            });
        })
            .catch(function (Error) {
            _this.submitting = false;
        });
    };
    AdminEditFormTempalteController.prototype.addQuestion = function () {
        var _this = this;
        var question = structuredClone(this.question);
        var valid = this.validateQuestion(question);
        if (valid === false) {
            this.FlashService.setMessage({
                type: "error",
                message: "The question is invalid",
            });
        }
        this.FormTemplateModel.createFormTemplateQuestion(this.formTemplate.id, this.question).then(function (response) {
            var questions = __spreadArray(__spreadArray([], _this.formTemplate.questions, true), [response.question], false);
            _this.formTemplate.questions = questions;
        });
    };
    AdminEditFormTempalteController.prototype.addOption = function () {
        if (this.question.type === "multiplechoice") {
            this.question.options = __spreadArray(__spreadArray([], this.question.options, true), [
                {
                    is_correct: false,
                    option_text: "",
                },
            ], false);
        }
    };
    AdminEditFormTempalteController.prototype.validateQuestion = function (question) {
        //
        if (question.type === "multiplechoice") {
            return (question.options.filter(function (o) {
                return o.is_correct === true;
            }).length >= 1);
        }
        // booleans need to have 2 answers and there needs to be exactly one correct answer
        if (question.type === "boolean") {
            if (question.options.length != 2) {
                return false;
            }
            return (question.options.filter(function (o) {
                return o.is_correct === true;
            }).length === 1);
        }
        //
        return true;
    };
    AdminEditFormTempalteController.prototype.removeOption = function (index) {
        if (this.question.type === "multiplechoice") {
            this.question.options.splice(index, 1);
        }
    };
    return AdminEditFormTempalteController;
}());
AdminEditFormTempalteController.$inject = [
    "$window",
    "$rootScope",
    "$state",
    "FormTemplateModel",
    "FlashService",
    "formTemplate",
    "questions",
];
exports.default = AdminEditFormTempalteController;
