"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
var stripe_js_1 = require("@stripe/stripe-js");
var AdminPurchaseOnlineDriversEdController = /** @class */ (function () {
    function AdminPurchaseOnlineDriversEdController($scope, $state, $stateParams, UserService, SchoolsModel, FlashService, $location, $q, OnlineDriversEd, pk) {
        var _this = this;
        // @todo get publishable key from request to server
        this.STRIPE_PUBLISHABLE_KEY = pk;
        this.$state = $state;
        this.$stateParams = $stateParams;
        this.$location = $location;
        this.UserService = UserService;
        this.SchoolsModel = SchoolsModel;
        this.FlashService = FlashService;
        this.OnlineDriversEd = OnlineDriversEd;
        this.paymentMethods = [];
        this.SchoolsModel = SchoolsModel;
        this.currentUser = UserService.getCurrentUser();
        this.setupIntentClientSecret = "";
        this.returnUrl = $location.absUrl();
        this.setupIntent = {};
        this.setupIntentMessage = '';
        this.setupIntentStatus = '';
        this.selectedPlan = '';
        this.handleCompletedPaymentIntent = this.handleCompletedPaymentIntent.bind(this);
        this.purchaseOnlineDriversEd = this.purchaseOnlineDriversEd.bind(this);
        try {
            var queryString = new URLSearchParams(window.location.search);
            if (queryString.has("setup_intent_client_secret")) {
                this.setupIntentClientSecret = queryString.get("setup_intent_client_secret");
            }
            if (queryString.has("ode_plan")) {
                this.selectedPlan = queryString.get("ode_plan");
            }
        }
        catch (Error) {
            this.setupIntentClientSecret = "";
        }
        if (this.setupIntentClientSecret !== "") {
            this.handleCompletedPaymentIntent().then(function (response) {
                $scope.$apply(function () {
                    _this.setupIntentMessage = response.message,
                        _this.setupIntentStatus = 'success';
                });
            });
        }
        if (this.setupIntentClientSecret === "") {
            this.getPaymentMethodIntent();
        }
    }
    AdminPurchaseOnlineDriversEdController.prototype.handleCompletedPaymentIntent = function () {
        return __awaiter(this, void 0, void 0, function () {
            var stripe, _a, error, setupIntent, res;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0: return [4 /*yield*/, (0, stripe_js_1.loadStripe)(this.STRIPE_PUBLISHABLE_KEY)];
                    case 1:
                        stripe = _b.sent();
                        return [4 /*yield*/, stripe.retrieveSetupIntent(this.setupIntentClientSecret)];
                    case 2:
                        _a = _b.sent(), error = _a.error, setupIntent = _a.setupIntent;
                        this.setupIntent = setupIntent;
                        if (error) {
                            console.info('stripe error');
                            console.error(error);
                        }
                        return [4 /*yield*/, this.OnlineDriversEd.purchase({
                                'payment_method_token': setupIntent.payment_method,
                                'ode_plan': this.selectedPlan
                            })];
                    case 3:
                        res = _b.sent();
                        this.setupIntentMessage = res.message;
                        this.setupIntentStatus = "success";
                        return [2 /*return*/, {
                                message: this.setupIntentMessage,
                                status: this.setupIntentStatus
                            }];
                }
            });
        });
    };
    AdminPurchaseOnlineDriversEdController.prototype.purchaseOnlineDriversEd = function () {
        var _this = this;
        this.OnlineDriversEd.purchase({
            'ode_plan': this.selectedPlan
        }).then(function (response) {
            _this.setupIntentMessage = response.message;
            _this.setupIntentStatus = "success";
        });
    };
    AdminPurchaseOnlineDriversEdController.prototype.getPaymentMethodIntent = function () {
        var _this = this;
        this.SchoolsModel.getPaymentMethodIntent(this.currentUser.school_id).then(function (response) {
            _this.intent = response.intent;
        });
    };
    AdminPurchaseOnlineDriversEdController.prototype.selectPlan = function (plan) {
        this.returnUrl = this.$location.absUrl() + "?ode_plan=" + plan;
        this.selectedPlan = plan;
    };
    return AdminPurchaseOnlineDriversEdController;
}());
AdminPurchaseOnlineDriversEdController.$inject = ['$scope', '$state', '$stateParams', 'UserService', 'SchoolsModel', 'FlashService', '$location', '$q', 'OnlineDriversEd', 'pk'];
exports.default = AdminPurchaseOnlineDriversEdController;
