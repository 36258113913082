"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var AdminCreateFormTempalteController = /** @class */ (function () {
    function AdminCreateFormTempalteController($window, $rootScope, $state, FormTemplateModel, FlashService) {
        this.$window = $window;
        this.$state = $state;
        this.FormTemplateModel = FormTemplateModel;
        this.FlashService = FlashService;
        this.loading = false;
        this.formTemplate = {
            name: "Undefined",
            type: "",
            status: "draft",
            due_date: "when_asssigned",
            questions: [],
        };
        this.question = {
            name: "",
            type: "multiplechoice",
            from: 1,
            to: 10,
            options: [{
                    option_text: "",
                    is_correct: false
                }]
        };
        this.page_by = 10;
        this.totalItems = 0;
        this.currentPage = 1;
    }
    AdminCreateFormTempalteController.prototype.continueToQuestions = function () {
        var _this = this;
        this.FormTemplateModel.createFormTemplate(this.formTemplate).then(function (response) {
            _this.$state.go('drivecoach.admin.formtemplates.edit', { 'template_id': response.form_template.id });
        });
    };
    return AdminCreateFormTempalteController;
}());
AdminCreateFormTempalteController.$inject = [
    "$window",
    "$rootScope",
    "$state",
    "FormTemplateModel",
    "FlashService"
];
exports.default = AdminCreateFormTempalteController;
