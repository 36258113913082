"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var queryString_1 = require("../helpers/queryString");
var FormTemplateModel = /** @class */ (function () {
    function FormTemplateModel($http, APPURL) {
        this.URLS = {
            FETCH: APPURL + '/api/v1/marketing/form-templates',
            UPDATE: APPURL + '/api/v1/marketing/form-templates',
            CREATE: APPURL + '/api/v1/marketing/form-templates'
        };
        this.$http = $http;
        this.data;
        this.extract = this.extract.bind(this);
        this.cacheResults = this.cacheResults.bind(this);
    }
    FormTemplateModel.prototype.extract = function (result) {
        return result.data;
    };
    FormTemplateModel.prototype.cacheResults = function (result) {
        this.data = this.extract(result);
        return this.data;
    };
    FormTemplateModel.prototype.get = function (template_id) {
        return this.$http.get(this.URLS.FETCH + '/' + template_id).then(this.cacheResults);
    };
    ;
    FormTemplateModel.prototype.getFormTemplates = function (data) {
        if (data === void 0) { data = null; }
        if (data !== null) {
            return this.$http.get(this.URLS.FETCH + '?' + (0, queryString_1.toQueryString)(data)).then(this.cacheResults);
        }
        return this.$http.get(this.URLS.FETCH).then(this.cacheResults);
    };
    ;
    FormTemplateModel.prototype.createFormTemplate = function (data) {
        return this.$http.post(this.URLS.FETCH, data).then(this.cacheResults);
    };
    ;
    FormTemplateModel.prototype.updateFormTemplate = function (template_id, data) {
        return this.$http.put(this.URLS.FETCH + '/' + template_id, data).then(this.cacheResults);
    };
    ;
    FormTemplateModel.prototype.getQuestions = function (template_id, data) {
        if (data === void 0) { data = null; }
        if (data !== null) {
            return this.$http.get(this.URLS.FETCH + '/' + template_id + '/questions?' + (0, queryString_1.toQueryString)(data)).then(this.cacheResults);
        }
        return this.$http.get(this.URLS.FETCH + '/' + template_id + '/questions').then(this.cacheResults);
    };
    ;
    FormTemplateModel.prototype.deleteQuestion = function (id) {
        return this.$http.delete(this.URLS.FETCH + '/questions/' + id).then(this.cacheResults);
    };
    FormTemplateModel.prototype.updateQuestion = function (template_id, data) {
        return this.$http.put(this.URLS.FETCH + '/' + template_id, data).then(this.cacheResults);
    };
    ;
    FormTemplateModel.prototype.createFormTemplateQuestion = function (template_id, data) {
        return this.$http.post(this.URLS.FETCH + '/' + template_id + '/questions', data).then(this.cacheResults);
    };
    ;
    FormTemplateModel.prototype.removeFormTemplateQuestion = function (template_id, question_id) {
        return this.$http.delete(this.URLS.FETCH + '/' + template_id + '/questions/' + question_id).then(this.cacheResults);
    };
    ;
    FormTemplateModel.prototype.destroy = function (id) {
        return this.$http.delete(this.URLS.FETCH + '/' + id).then(this.cacheResults);
    };
    return FormTemplateModel;
}());
FormTemplateModel.$inject = ['$http', 'APPURL'];
exports.default = FormTemplateModel;
