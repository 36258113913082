"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var queryString_1 = require("../helpers/queryString");
var json_1 = require("../helpers/json");
var SchoolsModel = /** @class */ (function () {
    function SchoolsModel($http, APPURL) {
        var _this = this;
        this.getSchoolByID = function (id) {
            return this.$http.get(this.URLS.FETCH + '/' + id).then(this.cacheSchool);
        };
        this.getMap = function (id, data) {
            if (data.student_id) {
                return this.$http.get(this.URLS.FETCH + '/' + id + '/map?' + (0, queryString_1.toQueryString)(data)).then(function (response) {
                    return response.data;
                });
            }
            return this.$http.get(this.URLS.FETCH + '/map').then(function (response) {
                return response.data;
            });
        };
        this.getSchools = function () {
            return this.$http.get(this.URLS.FETCH).then(this.cacheResults);
        };
        this.createSchool = function (school) {
            return this.$http.post(this.URLS.CREATE, school).then(this.cacheResults);
        };
        this.getSchoolByCode = function (code) {
            return this.$http.post(this.URLS.FETCH + '/code/' + code).then(this.cacheResults);
        };
        this.getSchoolPlan = function (id) {
            return this.$http.get(this.URLS.FETCH + '/' + id + '/plan').then(this.cacheResults);
        };
        this.getPaymentMethods = function (id) {
            return this.$http.get(this.URLS.FETCH + '/' + id + '/payment-methods').then(this.cacheResults);
        };
        this.addPaymentMethod = function (id, data) {
            return this.$http.post(this.URLS.FETCH + '/' + id + '/payment-methods', data).then(this.cacheResults);
        };
        this.getPaymentMethodIntent = function (id) {
            return this.$http.get(this.URLS.FETCH + '/' + id + '/payment-methods/intent').then(this.cacheResults);
        };
        this.getSchoolCharges = function (id) {
            return this.$http.get(this.URLS.FETCH + '/' + id + '/charges').then(this.cacheResults);
        };
        this.getSchoolInvoices = function (id) {
            return this.$http.get(this.URLS.FETCH + '/' + id + '/invoices').then(this.cacheResults);
        };
        this.cancelSubscription = function (id) {
            return this.$http.delete(this.URLS.FETCH + '/' + id + '/subscriptions').then(this.cacheResults);
        };
        this.verifyUniqueCode = function (code) {
            return this.$http.post(this.URLS.FETCH + '/unique/code', { 'code': code }).then(this.cacheResults);
        };
        this.editSchool = function (school) {
            return this.$http.put(this.URLS.CREATE + '/' + school.id, school).then(this.cacheResults);
        };
        this.getTasks = function (id) {
            return this.$http.get(this.URLS.FETCH + '/' + id + '/tasks').then(this.cacheResults);
        };
        this.addTask = function (data) {
            return this.$http.post(this.URLS.FETCH + '/' + data.school_id + '/tasks', data).then(this.cacheResults);
        };
        this.updateTasks = function (data) {
            return this.$http.put(this.URLS.FETCH + '/' + data.school_id + '/tasks', data).then(this.cacheResults);
        };
        /** @todo investigate */
        this.getHighSchools = function (data) {
            return this.$http.post(this.URLS.BASE + '/highschools/find', { 'location': data }).then(this.cacheResults);
        };
        /** @todo investigate */
        this.previewServiceAreaForLocation = function (data) {
            return this.$http.get(this.URLS.BASE + '/preview-service-area?' + (0, queryString_1.toQueryString)(data)).then(this.cacheResults);
        };
        this.addAdhocPickupLocation = function (data) {
            return _this.$http.post(_this.URLS.FETCH + '/' + data.school_id + '/ad-hoc-pickup-locations', { 'location': data }).then(_this.cacheResults);
        };
        this.updateAdhocPickupLocation = function (school_id, location_id, data) {
            return _this.$http.put(_this.URLS.FETCH + '/' + school_id + '/ad-hoc-pickup-locations/' + location_id, data).then(_this.cacheResults);
        };
        this.getAdhocPickupLocations = function (school_id, params) {
            if (params === void 0) { params = {}; }
            if (!params) {
                return _this.$http.get(_this.URLS.FETCH + '/' + school_id + '/ad-hoc-pickup-locations').then(_this.cacheResults);
            }
            return _this.$http.get(_this.URLS.FETCH + '/' + school_id + '/ad-hoc-pickup-locations?' + (0, queryString_1.toQueryString)(params)).then(_this.cacheResults);
        };
        this.removeAddhocPickupLocation = function (data) {
            return _this.$http.delete(_this.URLS.FETCH + '/' + data.school_id + '/ad-hoc-pickup-locations/' + data.location_id).then(_this.cacheResults);
        };
        this.$http = $http;
        this.URLS = {
            BASE: APPURL + '/api/v1',
            FETCH: APPURL + '/api/v1/schools',
            UPDATE: APPURL + '/api/v1/schools',
            CREATE: APPURL + '/api/v1/schools'
        };
        this.schools = [];
        this.school = {};
        this.extract = this.extract.bind(this);
        this.cacheResults = this.cacheResults.bind(this);
        this.cacheSchool = this.cacheSchool.bind(this);
    }
    SchoolsModel.prototype.extract = function (result) {
        return result.data;
    };
    SchoolsModel.prototype.cacheResults = function (result) {
        this.schools = this.extract(result);
        return this.schools;
    };
    SchoolsModel.prototype.cacheSchool = function (result) {
        this.school = this.extract(result);
        this.school.address = (0, json_1.isJson)(this.school.address) ? JSON.parse(this.school.address) : this.school.address;
        return this.school;
    };
    return SchoolsModel;
}());
SchoolsModel.$inject = ['$http', 'APPURL'];
exports.default = SchoolsModel;
