<!--//this component is used in the create course and edit course views-->
<template>
  <div class="well">
    <h2 class="well-heading" v-if="type === 'create-course' || type === 'edit-course'">Course Preview</h2>
    <div v-else-if="type === 'create-product' || type === 'view-course'" :class="{'flex flex-row': type === 'view-course'}">
      <h2 class="well-heading" :class="{'mb0': type === 'view-course'}">Course Details</h2>
      <div v-if="type === 'view-course'" class="flex flex-row btn-container">
        <button class="btn btn-blue mr10" @click="emitEvent('link-click', { destination: 'drivecoach.admin.courses.edit', params: { courseId: course.id }})">Edit</button>
        <button class="btn btn-red" @click="handleDelete" :disabled="buttonDisabled">Delete</button>
      </div>
    </div>
    
    <!-- <p v-if="type === 'create-product'" class="info blue"><i class="fa fa-info-circle fa-fw"></i>The following course details will be included in the email your customers receive upon successful checkout or when they are manually added to the Course.</p> -->
    
    <div class="well-content">
      <div v-for="(value, key, index) in coursePreview" :key="index" 
           :class="{ 'error': type === 'create-course' && errors[key].$error }">

        <div v-if="key === 'classes'">
          <div v-for="(classDate, index) in course.classes" :key="classDate.id" class="well-item" >
            <p><a href="#" @click.prevent="selectClassDate(classDate)"><strong>Class {{ index + 1 }}</strong></a></p>
            <p>{{ classDate.date }} From {{ classDate.startTime }} to {{ classDate.endTime }}</p>
            <p v-if="classDate.instructor && classDate.instructor.id != 0">Instructor: <a :style="{cursor: 'pointer'}" @click="emitEvent('link-click', { params: {instructorId: classDate.instructor.id}, destination: 'drivecoach.admin.instructors.view' })">{{ classDate.instructor.fullname }}</a></p>
            <p v-if="classDate.instructor && classDate.instructor.id == 0">Instructor: None</p>
            <p v-if="!classDate.instructor">Instructor: None</p>
          </div>
        </div>

        <div v-else-if="key === 'description'" class="well-item">
          <p><strong>{{ value }}</strong>:</p>
          <span v-html="course[key]"></span>
        </div>

        <p v-else class="well-item"><strong>{{ value }}</strong>: {{ course[key] }}</p>

      </div>
    </div>
    
    <button v-if="type === 'create-course'" :disabled="formInvalid" class="btn btn-dc btn-block" @click="submit">Confirm and Create Course</button>
    <button v-else-if="type === 'edit-course'" :disabled="formInvalid || buttonDisabled" class="btn btn-dc btn-block" @click="submit">Update Course</button>
    
  </div>
</template>

<script>
  import moment from 'moment';

  export default {
    name: 'dcCoursePreview',
    props: {
      'course': { type: Object, default: () => {} },
      'type': { type: String, required: true }, //type tells this component which page its being rendered on
      'formInvalid': { type: Boolean },
      'buttonDisabled': { type: Boolean },
      'errors': { type: Object, default: () => {} }
    },
    data() {
      return {
        coursePreview: {
          name: 'Course Name',
          type: 'Course Type',
          number_of_seats: 'Number of Students',
          location: 'Location', 
          classroom: 'Classroom',
          description: 'Course Description',
          classes: 'Class '
        },
      }
    },
    methods: {
      handleDelete: function() {
        if(confirm('Are you sure you want to delete this Course?') === true) {
          this.$emit('delete-course')
        }
      },
      selectClassDate: function(classDate){
        let data = {
          id: classDate.id,
        }
        let course = {
          id: this.course.id,
          class_id: classDate.id,
          date: classDate.date.replace(/\s/g, ""),
          start: classDate.startTime,
          end: classDate.endTime,
        }
        this.$store.dispatch('SET_VIEW_COURSE', course);
        this.emitEvent('set-course-class-date', data);
      },
      emitEvent: function(eventName, dataObject) {
        this.$emit('emit-event', {eventName: eventName, dataObject: dataObject})
      },
      moment: function(date, format) {
        return moment(date).format(format);
      },
      submit: function() {
        this.$emit('submit')
      }
    },
    mounted: function() {

    }
  }

</script>

<style lang="scss" scoped>
  @import './css/preview.scss';
  .flex {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    &.btn-container {
      justify-content: flex-end;
    }
  }
</style>
