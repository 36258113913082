"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var queryString_1 = require("../helpers/queryString");
var CancelationModel = /** @class */ (function () {
    function CancelationModel($http, APPURL) {
        this.$http = $http;
        this.APPURL = APPURL;
        this.URLS = {
            FETCH: APPURL + '/api/v1/cancelations',
            UPDATE: APPURL + '/api/v1/cancelations',
            CREATE: APPURL + '/api/v1/cancelations'
        };
        this.data = [];
        this.cacheResults = this.cacheResults.bind(this);
        this.extract = this.extract.bind(this);
    }
    CancelationModel.prototype.extract = function (result) {
        return result.data;
    };
    CancelationModel.prototype.cacheResults = function (result) {
        this.data = this.extract(result);
        return this.data;
    };
    CancelationModel.prototype.getCancelations = function (data) {
        if (data === void 0) { data = null; }
        if (data !== null) {
            return this.$http.get(this.URLS.FETCH + '?' + (0, queryString_1.toQueryString)(data)).then(this.cacheResults);
        }
        return this.$http.get(this.URLS.FETCH).then(this.cacheResults);
    };
    ;
    return CancelationModel;
}());
CancelationModel.$inject = ['$http', 'APPURL'];
exports.default = CancelationModel;
