import angular from 'angular'
import controller from './document-templates.controller'
import template from './document-templates.html'

import CreateDocumentTemplate from './create-document/create-document.module'

function config( $stateProvider, $urlRouterProvider, USER_ROLES ) {
    $stateProvider
        .state('drivecoach.admin.documenttemplates', {
            'url' : '/document-templates',
            'controller' : 'AdminDocumentTemplatesController',
            'controllerAs' : 'vm',
            'template' : template,
            data : {
                authorizedRoles : [
                    USER_ROLES.admin,
                    USER_ROLES.administrator,
                    USER_ROLES.owner
                ]
            }
        });
}
config.$inject = ['$stateProvider', '$urlRouterProvider', 'USER_ROLES'];

export default angular.module('admin.documenttemplates', [
    CreateDocumentTemplate.name,
])
    .config(config)
    .controller('AdminDocumentTemplatesController', controller)
;
