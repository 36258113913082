let siteUrl;
siteUrl = process.env.SITE_URL;
export const Config = angular.module("constants.config", [])
.constant("APPURL", process.env.APP_URL)
.constant("ENV", process.env.APP_ENV)
.constant("HTML_FIVE_MODE", process.env.HTML_FIVE_MODE || 'off')
.constant("SITEURL", siteUrl)
.constant("HTML_FIVE_MODE", process.env.HTML_FIVE_MODE || 'off')
.constant("OPENREPLAY_PROJECT_KEY", process.env.OPENREPLAY_PROJECT_KEY || null)
.constant("OPENREPLAY_INGEST_POINT", process.env.OPENREPLAY_INGEST_POINT || null)
.constant("PUSHER_KEY", process.env.PUSHER_KEY);
