import angular from "angular";
import controller from "./update-payment-method.controller";
import template from "./update-payment-method.html";
import Vue from "vue";
import "ngVue";

function getBillingInfo(BillingModel) {
    return BillingModel.getBillingInfo().then((response) => {
        return response.pk;
    });
}
getBillingInfo.$inject = ["BillingModel"];

function config($stateProvider, $urlRouterProvider, USER_ROLES) {
    $stateProvider.state("drivecoach.admin.schools.updatepaymentmethod", {
        url: "/update-payment-method",
        controller: controller,
        controllerAs: "vm",
        template: template,
        resolve: {
            pk: getBillingInfo,
        },
        data: {
            authorizedRoles: [
                USER_ROLES.admin,
                USER_ROLES.administrator,
                USER_ROLES.owner,
            ],
        },
    });
}
config.$inject = ["$stateProvider", "$urlRouterProvider", "USER_ROLES"];

export default angular
    .module("admin.dashboard.schools.updatepaymentmethod", [])
    .config(config)
    .controller("AdminSchoolUpdatePaymentMethodController", controller);
