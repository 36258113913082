class AdminDocumentTemplateController {
    constructor(
      $state,
      $scope,
      FlashService,
      $window,
    ) {
      this.$state = $state;
      this.$scope = $scope;
      this.FlashService = FlashService;
      this.$window = $window;
      this.busy = false;
    }
  }
  
  AdminDocumentTemplateController.$inject = [
    "$state",
    "$scope",
    "FlashService",
    "$window",
  ];
  
  export default AdminDocumentTemplateController;
  