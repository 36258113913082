"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var AdminFormTemplatesController = /** @class */ (function () {
    function AdminFormTemplatesController($window, $rootScope, $state, FormModel, FlashService) {
        var _this = this;
        this.$window = $window;
        this.$state = $state;
        this.FormModel = FormModel;
        this.FlashService = FlashService;
        this.loading = true;
        this.forms = [];
        this.page_by = 10;
        this.totalItems = 0;
        this.currentPage = 1;
        this.removeForm = this.removeForm.bind(this);
        this.FormModel.getForms({ 'page': this.currentPage, 'page_by': this.page_by }).then(function (response) {
            _this.forms = response.forms.data;
            _this.currentPage = response.forms.current_page;
            _this.totalItems = response.forms.total;
            _this.loading = false;
        }).catch(function (Error) {
            _this.loading = false;
        });
    }
    AdminFormTemplatesController.prototype.removeForm = function (form) {
        var _this = this;
        var confirm = this.$window.confirm('Are you sure you want to delete this form?');
        if (confirm === false) {
            return;
        }
        this.FormModel.destroy(form.id).then(function (response) {
            _this.FlashService.setMessage({
                message: response.message,
                type: 'success'
            });
            _this.forms = _this.forms.filter(function (form) {
                return form.id !== form.id;
            });
        }).catch(function (Error) {
        });
    };
    return AdminFormTemplatesController;
}());
AdminFormTemplatesController.$inject = [
    "$window",
    "$rootScope",
    "$state",
    "FormModel",
    "FlashService"
];
exports.default = AdminFormTemplatesController;
