class AdminCoursesEditController {
  constructor(UserService, PoliciesModel, $state, UsersModel, CoursesModel, FlashService, ClassroomsModel, course, courseTypes, LocationsModel)
  {
    this.course = course;
    this.user = UserService.getCurrentUser();
    this.courseTypes = courseTypes;
    this.today = new Date();
    this.loading = true;
    this.$state = $state;

    this.UsersModel = UsersModel;
    this.PoliciesModel = PoliciesModel;
    this.LocationsModel = LocationsModel;
    this.CoursesModel = CoursesModel;
    this.ClassroomsModel = ClassroomsModel;
    this.FlashService = FlashService;

    this.getInstructors = this.getInstructors.bind(this);
    this.getClassrooms = this.getClassrooms.bind(this);
    this.clearForm = this.clearForm.bind(this);
    this.updateForm = this.updateForm.bind(this);
    this.delete = this.deleteCourse.bind(this);
    this.submit = this.submit.bind(this);
    this.handleLinkClick = this.handleLinkClick.bind(this);
    this.handleLocationChange = this.handleLocationChange.bind(this);

    this.setup();
  }

  async setup() {
      const locations = await this.LocationsModel.getLocations();
      this.locations = locations;
      // const policiesResponse = this.PoliciesModel.getByID('courses');

      // set initial location
      this.course.location = this.locations.find(location => location.id === this.course.location.id);

      this.course.type = this.courseTypes.find(type => type.id === this.course.type.id);

      await this.getClassrooms();
      await this.getInstructors();

      this.course.classroom = this.classrooms.find(room => room.id === this.course.classroom.id);

      this.course.classes = this.course.classes.map(courseClass => {
        let id = courseClass.instructor ? courseClass.instructor.id : 0;
        courseClass.instructor = this.instructors.find(instructor => instructor.id === id);
        return courseClass;
      });

      this.loading = false;
    }

    getInstructors() {
      let params = {
          'location' : this.course.location
      };

      params.course = this.course;

      return this.UsersModel.searchInstructors(params).then(response => {
          this.instructors = [{'id' : 0, 'fullname' : 'None'}].concat(response.instructors);
      });
    }

    getClassrooms() {
      let params = {
          'location' : { id: this.course.location.id}
      };

      return this.ClassroomsModel.search(params).then(response => {
        this.classrooms = response.classrooms;
      });
    }

    clearForm() {
        this.course.classroom = null;
    }

    updateForm() {
        this.getClassrooms();
        this.getInstructors();
    }

    deleteCourse() {
        this.CoursesModel.delete(this.course).then(response => {
            this.FlashService.setMessage( {
                'type' : 'success',
                'message' : response.message
            } );
            this.$state.go( 'drivecoach.admin.calendar', {}, { 'reload' : true } );
        });
    }

    handleLocationChange(location_id) {
      this.course.location = this.locations.find((location) => location.id == location_id);
      this.getClassrooms();
      this.getInstructors();
    }

    handleLinkClick(data) {
      this.$state.go(data.destination, data.params);
    }

    submit(data) {
      this.submitted = true;

      const payload = Object.assign({}, data);
      payload.classes = payload.classes.map(c => {
          if (!c.instructor || c.instructor.id === 0) {
              delete c.instructor;
          }
          return c;
      });

      this.CoursesModel.update(payload).then( response => {
        this.FlashService.setMessage({
          'type' : 'success',
          'message' : response.message
        })
        this.submitted = false;
        this.$state.go('drivecoach.admin.courses.view', {courseId: this.course.id});
      }).catch(error => {
        console.log('error', error)
        this.submitted = false;
      })
    }

}
AdminCoursesEditController.$inject = ['UserService', 'PoliciesModel', '$state', 'UsersModel', 'CoursesModel', 'FlashService', 'ClassroomsModel', 'course', 'courseTypes', 'LocationsModel'];
export default AdminCoursesEditController;
