import angular from 'angular'
import Vue from 'vue';
import 'ngVue';

import DocumentEditor from './DocumentEditor.vue'

export default angular.module( 'admin.directives.documenteditor', [] )
  .directive('dcDocumentEditor', ['createVueComponent', function (createVueComponent) {
    return createVueComponent(Vue.component('dcDocumentEditor', DocumentEditor))
  }])
