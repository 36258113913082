import angular from 'angular'
import template from './admin.html'
import AdminController from './admin.controller'
import Dashboard from './dashboard/admin-dashboard.module'
import Drives from './drives/admin-drives.module'
import ReportCards from './dashboard/reportcards/admin-reportcards.module'
import Events from './events/admin-events.module'
import Courses from './courses/admin-courses.module'
import Settings from './settings/admin-settings.module'
import UsageReport from './usage-reports/usage-reports.module'
import CRM from './crm/crm.module'
import Orders from './crm/orders/admin-orders.module'
import Tasks from './tasks/admin-tasks.module'
import Emails from './emails/emails.module'
import LessonPlans from './lesson-plans/lesson-plans.module'
import ReportBuilder from './report-builder/report-builder.module'
import Notifications from './notifications/notifications.module'
import Documents from './documents/admin-documents.module'
import DocumentTemplates from './document-templates/document-templates.module'
import Forms from './forms/forms.module'
import FormTemplates from './form-templates/form-templates.module'
import Directives from './directives/admin-directives.module'
import {Services} from './services/services'
let modules = [
    Services.name,
    Directives.name,
    Dashboard.name,
    Drives.name,
    Events.name,
    Courses.name,
    Settings.name,
    UsageReport.name,
    CRM.name,
    Orders.name,
    Tasks.name,
    Emails.name,
    LessonPlans.name,
    ReportBuilder.name,
    Notifications.name,
    Documents.name,
    DocumentTemplates.name,
    Forms.name,
    FormTemplates.name,
];

function getCurrentUser(AuthService, UserService) {
    return AuthService.getCurrentUser().then(response => {
        UserService.setCurrentUser(response);
        return UserService.getCurrentUser();
    }).catch(Error => {
        console.log(Error)
    });
}
getCurrentUser.$inject = ['AuthService', 'UserService'];

function config( $stateProvider, $urlRouterProvider, USER_ROLES ) {
    $stateProvider
        .state( 'drivecoach.admin', {
            url : '/admin',
            controller : 'AdminController',
            controllerAs: 'vm',
            template : template,
            resolve : {
                currentUser: getCurrentUser
            },
            data : {
                authorizedRoles : [
                    USER_ROLES.admin,
                    USER_ROLES.administrator,
                    USER_ROLES.owner,
                    USER_ROLES.staff
                ]
            }
        } )
    ;
}
config.$inject = ['$stateProvider', '$urlRouterProvider', 'USER_ROLES'];

export default angular.module( 'admin', modules )
    .config(config)
    .controller('AdminController', AdminController);
